import { meetingAxiosClient, rgAxiosClient } from '../lib';

const api = {
    getCandidteCv: () => {
        return rgAxiosClient.get(`/polling/resume`, {
            responseType: 'blob',
        });
    },

    getMeetingInformation: (meetingId, params) => {
        return meetingAxiosClient.get(`/${meetingId}/starting`, { params });
    },

    getScoringResult: (email) => {
        return rgAxiosClient.get(`/polling/score`, { params: { email } });
    },

    getScoreInformation: () => {
        return rgAxiosClient.get(`/polling/score-info`);
    },

    scoreCandidate: (request) => {
        return rgAxiosClient.post(`/polling/score`, request);
    },

    getCandidateProfile: () => {
        return rgAxiosClient.get(`/internal-meeting/candidate`);
    },
};

export default api;

