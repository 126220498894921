import { CircularProgress } from '@material-ui/core';
import Backdrop, { BackdropProps } from '@material-ui/core/Backdrop';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { setAuthTokens } from 'axios-jwt';
import isElectron from 'is-electron';
import { useSnackbar } from 'notistack';

import moment from 'moment';
import PropTypes from 'prop-types';
import { useState } from 'react';
import CookieConsent from 'react-cookie-consent';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import { meetingApi } from '../../api';
import { config } from '../../config';

const styles = (theme) => ({
    root: {
        display: 'flex',
        width: '100%',
        height: '100%',
        backgroundColor: 'var(--background-color)',
        // backgroundImage: `url(${config.background})`,
        backgroundAttachment: 'fixed',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
    },
    dialogPaper: {
        width: '30vw',
        padding: theme.spacing(2),
        [theme.breakpoints.down('lg')]: {
            width: '40vw',
        },
        [theme.breakpoints.down('md')]: {
            width: '50vw',
        },
        [theme.breakpoints.down('sm')]: {
            width: '70vw',
        },
        [theme.breakpoints.down('xs')]: {
            width: '90vw',
            margin: 0,
        },
    },
    accountButton: {
        padding: 0,
    },

    joinButton: {
        [theme.breakpoints.down(600)]: {
            width: '100%',
        },
    },
});

const isProd = process.env.REACT_APP_PROD === 'true';

const DialogBackDropComponent = (props: BackdropProps) => <Backdrop {...props} style={{ zIndex: -1, backgroundColor: '#161929' }} />;

const DialogTitle = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
        paddingBottom: theme.spacing(0),
    },
}))(MuiDialogTitle);

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        paddingTop: theme.spacing(0),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const OfflineJoinDialog = ({ classes, onJoin }) => {
    const location = useLocation();
    const { enqueueSnackbar } = useSnackbar();

    const intl = useIntl();
    const history = useHistory();

    const roomId = decodeURIComponent(location.pathname.replace('/score', '')).slice(1);
    const url = new URL(window.location.href);
    const token = url.searchParams.get('token');

    const [displayName, setDisplayName] = useState(url.searchParams.get('email') || '');

    //Get initial information of this meeting
    const [isLoading, setIsLoading] = useState(false);

    const joinRoom = async (email, token, loading = true) => {
        try {
            setAuthTokens({
                accessToken: token,
                refreshToken: token,
            });
            setIsLoading(loading);
            const roomInformation: any = await meetingApi.getScoreInformation();
            if (roomInformation) {
                const startTime = moment(roomInformation.startAt * 1000);
                const readyTime = startTime.subtract(15, 'minutes');

                //Room ready at 15 minutes before start time
                const isReady = readyTime.isBefore(moment());
                const isPanelist = roomInformation?.panelists?.includes(email) && roomInformation.isPanelist;

                if (isPanelist) {
                    if (isReady || !isProd) {
                        onJoin?.();
                        history.replace(`?token=${token}&email=${email}`);
                    } else {
                        throw new Error(`This room will be ready on ${readyTime.format('dddd, MMM Do YYYY, HH:mm:ss')}`);
                    }
                } else {
                    throw new Error(`You do not have permission`);
                }
            }
        } catch (error) {
            console.error(error);

            enqueueSnackbar(error.message ? error.message : error, {
                variant: 'error',
                autoHideDuration: 3000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleFocus = (event) => event.target.select();

    const handleJoinUsingEnterKey = (event) => {
        if (event.key === 'Enter') document.getElementById('joinButton').click();
    };

    return (
        <div className={classes.root}>
            {isLoading ? (
                <Box style={{ width: '100vw', paddingTop: '20px', textAlign: 'center' }}>
                    <CircularProgress style={{ color: '#fff' }} />
                </Box>
            ) : (
                <Dialog
                    onKeyDown={handleJoinUsingEnterKey}
                    open
                    classes={{
                        paper: classes.dialogPaper,
                    }}
                    BackdropComponent={DialogBackDropComponent}
                >
                    <DialogTitle
                        title={
                            (
                                <Typography variant="h5" component="span" style={{ color: '#0a6abf' }}>
                                    {config.title}
                                </Typography>
                            ) as any
                        }
                        disableTypography
                    ></DialogTitle>

                    <DialogContent>
                        <hr />
                        {/* ROOM NAME */}
                        <Typography variant="h5">
                            {intl.formatMessage({
                                id: 'label.roomName',
                                defaultMessage: 'Room name',
                            })}
                            : {roomId}
                        </Typography>
                        {/* /ROOM NAME */}

                        {/* NAME FIELD */}
                        <TextField
                            id="displayname"
                            label={intl.formatMessage({
                                id: 'label.yourEmail',
                                defaultMessage: 'Your Email',
                            })}
                            value={displayName}
                            variant="outlined"
                            onFocus={handleFocus}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <AccountCircle />
                                    </InputAdornment>
                                ),
                            }}
                            margin="normal"
                            onChange={(event) => {
                                const { value } = event.target;

                                setDisplayName(value);
                            }}
                            onKeyDown={(event) => {
                                const { key } = event;

                                switch (key) {
                                    case 'Enter':
                                    case 'Escape': {
                                        setDisplayName((prev) => prev.trim());
                                        break;
                                    }
                                    default:
                                        break;
                                }
                            }}
                            onBlur={() => {
                                setDisplayName((prev) => prev.trim());
                            }}
                            fullWidth
                        />
                    </DialogContent>

                    <DialogActions style={{ textAlign: 'center' }}>
                        <Button
                            onClick={() => {
                                joinRoom(displayName, token, false);
                            }}
                            variant="contained"
                            color="primary"
                            id="joinButton"
                            disabled={displayName === ''}
                        >
                            <FormattedMessage id="label.join" defaultMessage="Join" />
                        </Button>
                    </DialogActions>

                    {!isElectron() && (
                        <CookieConsent
                            buttonText={intl.formatMessage({
                                id: 'room.consentUnderstand',
                                defaultMessage: 'I understand',
                            })}
                        >
                            <FormattedMessage
                                id="room.cookieConsent"
                                defaultMessage="This website uses cookies to enhance the user experience"
                            />
                        </CookieConsent>
                    )}
                </Dialog>
            )}
        </div>
    );
};

OfflineJoinDialog.propTypes = {
    onJoin: PropTypes.func.isRequired,
};

export default withStyles(styles)(OfflineJoinDialog);
