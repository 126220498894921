import { Suspense, useEffect, useState } from 'react';
import OfflineJoinDialog from './OfflineJoinDialog';
import LoadingView from '../Loader/LoadingView';
import { LazyPreload } from '../Loader/LazyPreload';

const Room = LazyPreload(() => import(/* webpackChunkName: "room" */ './OfflineScoreRoom'));

const ScoreOfflineInterviewScreen = (props) => {
    const [joined, setJoined] = useState(false);

    useEffect(() => {
        (Room as any).preload();

        return;
    }, []);

    if (!joined) {
        return (
            <OfflineJoinDialog
                onJoin={() => {
                    setJoined(true);
                }}
            />
        );
    } else {
        return (
            <Suspense fallback={<LoadingView />}>
                <Room
                    onLeave={() => {
                        setJoined(false);
                    }}
                />
            </Suspense>
        );
    }
};

ScoreOfflineInterviewScreen.propTypes = {};

export default ScoreOfflineInterviewScreen;
