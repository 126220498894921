import { makeStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import classnames from 'classnames';
import DOMPurify from 'dompurify';
import marked from 'marked';
import { CustomContentProps, SnackbarContent, useSnackbar } from 'notistack';
import { forwardRef, useCallback } from 'react';
import { FormattedTime } from 'react-intl';
import { Message } from '../../types/meeting';
import EmptyAvatar from '../../images/avatar-empty.jpeg';

const useStyles = makeStyles(() => ({
    root: {
        '@media (min-width:600px)': {
            minWidth: '344px !important',
        },
    },
    card: {
        width: '100%',
    },
    typography: {
        color: '#000',
    },
    actionRoot: {
        padding: '8px 8px 8px 16px',
        justifyContent: 'space-between',
    },
    icons: {
        marginLeft: 'auto',
    },
    expand: {
        padding: '8px 8px',
        transform: 'rotate(0deg)',
        color: '#000',
        transition: 'all .2s',
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    paper: {
        backgroundColor: '#fff',
        padding: 16,
    },
    checkIcon: {
        fontSize: 20,
        paddingRight: 4,
    },
    button: {
        padding: 0,
        textTransform: 'none',
    },
    paperRoot: {
        display: 'flex',
        flexShrink: 0,
        backgroundColor: '#5f9b2d5c',
        boxShadow: 'none',
        padding: '0',
        wordWrap: 'break-word',
        wordBreak: 'break-all',
    },
    single: {
        marginTop: '8px',
        borderRadius: '10px 10px 10px 10px',
    },

    combinedTime: {
        width: '75px',
        alignSelf: 'center',
        fontSize: '13px',
        color: '#999999',
        dispay: 'flex',
        display: 'flex',
        justifyContent: 'center',
    },
    sent: {
        alignSelf: 'flex-end',
    },
    received: {
        alignSelf: 'flex-start',
    },
    name: {},
    avatar: {
        dispay: 'flex',
        width: '75px',
        display: 'flex',
        justifyContent: 'center',
        '& img': {
            borderRadius: '50%',
            width: '2rem',
            height: '2rem',
            alignSelf: 'center',
            objectFit: 'cover',
            backgroundColor: '#e0e0e085',
        },
    },
    content: {
        margin: '8px',
        '& p': {
            margin: '0',
        },
    },
}));

interface MessagePopoverProps extends CustomContentProps {
    messageData: Message;
}
const linkRenderer = new marked.Renderer();

linkRenderer.link = (href, title, text) => {
    title = title ? title : href;
    text = text ? text : href;

    return `<a target='_blank' href='${href}' title='${title}'>${text}</a>`;
};

const allowedHTMLNodes = {
    ALLOWED_TAGS: ['a', 'b', 'strong', 'i', 'em', 'u', 'strike', 'p', 'br'],
    ALLOWED_ATTR: ['href', 'target', 'title'],
};

const MessagePopover = forwardRef<HTMLDivElement, MessagePopoverProps>(({ id, messageData, ...props }, ref) => {
    const classes = useStyles();
    const { closeSnackbar } = useSnackbar();

    const handleDismiss = useCallback(() => {
        closeSnackbar(id);
    }, [id, closeSnackbar]);

    return (
        <Paper className={classnames(classes.paperRoot, classes.received, null, classes.single)}>
            <SnackbarContent ref={ref} className={classes.root}>
                <Card className={classes.card} style={{ backgroundColor: '#fddc6c' }}>
                    <CardActions classes={{ root: classes.actionRoot }}>
                        {/* Avatar */}
                        <div className={classes.avatar}>
                            <img src={EmptyAvatar} alt="Avatar" />
                        </div>
                        {/* /Avatar */}

                        {/* Content */}
                        <div className={classes.content}>
                            {/* Name & Time */}
                            <Typography variant="subtitle1">
                                <b>
                                    <b>{messageData?.name}</b> - <FormattedTime value={new Date(messageData.time)} />
                                </b>
                            </Typography>
                            {/* /Name & Time */}

                            {/* Text */}
                            <Typography
                                variant="subtitle1"
                                // eslint-disable-next-line react/no-danger
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(
                                        marked.parse(messageData.text, { renderer: linkRenderer }),
                                        allowedHTMLNodes
                                    ),
                                }}
                            />
                            {/* /Text */}
                        </div>
                        {/* Content */}

                        <div className={classes.icons}>
                            <IconButton size="small" className={classes.expand} onClick={handleDismiss}>
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </div>
                    </CardActions>
                </Card>
            </SnackbarContent>
        </Paper>
    );
});

MessagePopover.displayName = 'MessagePopover';

export default MessagePopover;
