import React from 'react';
import { Route, Switch } from 'react-router-dom';
import OfflineJoinDialog from './OfflineJoinDialog';
import ScoreOfflineInterviewScreen from './ScoreOfflineInterviewScreen';
import { useRouteMatch } from 'react-router-dom';

interface IOfflineScoreRoutesProps {}

const OfflineScoreRoutes: React.FunctionComponent<IOfflineScoreRoutesProps> = (props) => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route exact path={`${path}/`}>
                <OfflineJoinDialog />
            </Route>
            <Route path={`${path}/:roomId`}>
                <ScoreOfflineInterviewScreen />
            </Route>
        </Switch>
    );
};

export default OfflineScoreRoutes;
